import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import FilterForm from "../FilterForm/FilterForm";
import './Home.css';
import Typewriter from "typewriter-effect";

const Home = () => {
    return (
        <Container fluid className="home-section" id="home">
            <Row>
                <Col style={{padding:"3rem"}}>
                    <Typewriter
                        options={{
                            strings: [
                                "Open Plots",
                                "Villa Plots",
                                "Farm Plots",
                                "Commerical Plots",
                                "Premium Plots"
                            ],
                            autoStart: true,
                            loop: true,
                            deleteSpeed: 50
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FilterForm />
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
