import { Card, CardContent, Typography } from '@material-ui/core';
import "./PropertyCard.css"
import React from "react";

// A function to render a card for each property
const PropertyCard = ({ property }) => {

    return (
        <Card className="card">
            <CardContent className="cardContent" style={{textAlign: "left"}}>
                <Typography variant="body1" component="p" className="propertyInfo">
                    <span className="purple">{property.title}</span><br />
                    {property.plotArea} Sq yards {property.type} <br />
                    {property.facing} facing<br />
                    {property.roadSize} feet road <br />
                    {property.layoutYear}  {property.layoutType} {property.layoutArea} acres Layout<br />
                </Typography>
                <Typography variant="body1" className="propertyPrice">
                    Price: Rs. {property.price} per yard
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className="additionalInfo">
                    Posted Date: {property.created_at}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className="noteInfo">
                    Note: {property.comments}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className="noteInfo">
                    Property Code : ##{property.id}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default PropertyCard;