import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropertyList from "../PropertyList/PropertyList";
import supabase from "../../config/supabaseClient"
import "./FilterForm.css";

const FilterForm = ({  }) => {
    const [districts, setDistricts] = useState([]);
    const [mandals, setMandals] = useState([]);
    const [villages, setVillages] = useState([]);

    const [selectedMandal, setSelectedMandal] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedVillage, setSelectedVillage] = useState(null);

    const [propertyList, setPropertyList] = useState([]);
    const [load, setLoad] = useState(false);

    const STATE = "Telangana";

    function removeDuplicates(array) {
        return Array.from(new Set(array));
    }

    // Fetch the available districts based on the selected state
    useEffect(() => {
        const fetchDistricts = async () => {
            try {
                const { data, error } = await supabase
                    .from('location')
                    .select('district')
                    .eq('state', STATE);

                const districts = data.map((result) => result.district);

                setDistricts(removeDuplicates(districts).sort());

            } catch (err) {
                console.error(err);
            }
        };

        fetchDistricts();

    }, []);

    // Fetch the available mandals based on the selected state
    useEffect(() => {
        const fetchMandals = async () => {
            try {
                const { data, error } = await supabase
                    .from('location')
                    .select('mandal')
                    .eq('district', selectedDistrict.value);

                const mandals = data.map((result) => result.mandal);
                setMandals(removeDuplicates(mandals).sort());
            } catch (err) {
                console.error(err);
            }
        };

        if (selectedDistrict) {
            fetchMandals();
        }
    }, [selectedDistrict]);

    // Fetch the available villages based on the selected state
    useEffect(() => {
        const fetchVillages = async () => {
            try {
                const { data, error } = await supabase
                    .from('location')
                    .select('village')
                    .eq('mandal', selectedMandal.value);

                const villages = data.map((result) => result.village);
                setVillages(removeDuplicates(villages).sort());
            } catch (err) {
                console.error(err);
            }
        };

        if (selectedDistrict) {
            fetchVillages();
        }
    }, [selectedMandal]);

    // Fetch the available properties based on the selected village
    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const locations = await supabase
                    .from('location')
                    .select('id')
                    .eq('village', selectedVillage.value);

                const {data, error} = await supabase
                    .from('landinformation')
                    .select('*')
                    .eq('location', locations.data[0].id)
                    .order('id', { ascending: true });

                setPropertyList(data);
                setLoad(false);
            } catch (err) {
                console.error(err);
            }
        };

        if (selectedVillage) {
            fetchProperties();
        }
    }, [selectedVillage]);

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrict(selectedOption);

        // When the district is cleared, reset Mandal and Village selections
        if (!selectedOption) {
            setSelectedMandal(null);
            setSelectedVillage(null);
        }
    };


    const handleMandalChange = (selectedOption) => {
        setSelectedMandal(selectedOption);
        if (!selectedOption) {
            setSelectedVillage(null);
        }
    };

    const handleVillageChange = (selectedOption) => {
        setLoad(true);
        setSelectedVillage(selectedOption);
    };

    return (
        <div>
            <div className="filter-form">
                    <Select
                        id="district"
                        value={selectedDistrict}
                        onChange={handleDistrictChange}
                        options={districts.map(district => ({ value: district, label: district }))}
                        isClearable
                        placeholder="District"
                        className="select-filter"
                    />
                    <Select
                        id="mandal"
                        value={selectedMandal}
                        onChange={handleMandalChange}
                        options={mandals.map(mandal => ({ value: mandal, label: mandal }))}
                        isClearable
                        placeholder="Mandal"
                        className="select-filter"
                    />
                    <Select
                        id="village"
                        value={selectedVillage}
                        onChange={handleVillageChange}
                        options={villages.map(village => ({ value: village, label: village }))}
                        isClearable
                        placeholder="Village"
                        className="select-filter"
                    />
            </div>
            {(selectedDistrict && selectedMandal && selectedVillage) ? (
                load ? <div className="loader" /> :
                propertyList.length > 0 ?
                <PropertyList
                    propertyList={propertyList}
                /> : <h2 className="message">There are no properties available with the above filter.</h2>
            ) : (
                <h2 className="message">Please fill all filters to see the list.</h2>
            )}
        </div>
    );
};

export default FilterForm;
