import supabase from "../config/supabaseClient";

const getRecords = async (table) => {
    try {
        const { data, error } = await supabase
            .from(table)
            .select('');

        if (error) {
            throw error;
        }

        return data;
    } catch (error) {
        console.error('Error fetching location data:', error.message);
        return null;
    }
}

const insertRecord = async(table, record) => {
    const { data, error } = await supabase
        .from(table)
        .insert(record);
    return {data, error};
}

const names = {
    "location" : "Location",
    "landinformation" : "Land Information",
    "referral" : "Referral Linked in Land Information",
    "customer" : "Buyer/Seller Information",
    "agent" : "Become an Agent Information"
}

const getColumns = (data)  => {
    const columns = new Set(); // Use Set to store unique column names
    data.forEach(item => {
        Object.keys(item).forEach(key => {
            columns.add(key); // Add each key (column name) to the Set
        });
    });
    return Array.from(columns); // Convert Set back to an array and return
}

const excludeColumns = {
    "location" : ["id", "state"],
    "landinformation" : ["id", "postedDate"],
    "referral" : ["id"],
    "customer" : ["id"],
    "agent" : ["id"]
}

export {getRecords, insertRecord, getColumns, names, excludeColumns}
