import React from "react";
import {Container} from 'react-bootstrap';

function AboutCard() {
  return (
      <Container>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
              Welcome to <span className="purple">Plottss.com </span>, your dedicated destination for exceptional real estate services in Telangana.
              <br /><br />
              At <span className="purple">Plottss.com </span>, we pride ourselves on being your one-stop solution for all your commercial plot needs, specializing in buying, selling, and expertly navigating the real estate market.
              Driven by a passion for client satisfaction, we believe in personalized experiences tailored to meet your unique requirements.
              Our team boasts in-depth local knowledge and unwavering dedication, ensuring that every step of your real estate journey is supported by guidance steeped in integrity and expertise.

              Whether you're seeking to invest, buy, or sell commercial properties, Plottss.com is committed to delivering unparalleled service.
              Our aim is to exceed your expectations and make your real estate endeavors seamless and rewarding.<br /><br />
              Explore the difference with <span className="purple">Plottss.com </span> and let us guide you through the dynamic landscape of commercial real estate in Telangana.
          </p>
        </blockquote>
      </Container>

  );
}

export default AboutCard;
