import React, { useState, useEffect } from 'react';
import Select from "react-select";
import PropertyCard from "./PropertyCard";
import "./PropertyList.css"
import {AiTwotonePhone} from "react-icons/ai";

const PropertyList = ({propertyList}) => {

    const [properties, setProperties] = useState(propertyList);
    function removeDuplicates(array) {
        return Array.from(new Set(array));
    }

    // Plot Type Filter
    const [plotTypes, setPlotTypes] = useState([]);
    const [selectedPlotType, setSelectedPlotType] = useState(null);

    const handlePlotTypeChange = (selectedOption) => {
        setSelectedPlotType(selectedOption);
    };

    useEffect(() => {
        const fetchPlotTypes = async () => {
            try {
                const plotTypes = propertyList.map((result) => result.type);
                setPlotTypes(removeDuplicates(plotTypes));
            } catch (err) {
                console.error(err);
            }
        };

        if(propertyList.length > 0)
            fetchPlotTypes();
    },[propertyList]);

    // Facing Filter
    const [facings, setFacings] = useState([]);
    const [selectedFacing, setSelectedFacing] = useState(null);

    const handleFacingChange = (selectedOption) => {
        setSelectedFacing(selectedOption);
    };

    useEffect(() => {
        const fetchFacings = async () => {
            try {
                const facings = propertyList.map((result) => result.facing);
                setFacings(removeDuplicates(facings));
            } catch (err) {
                console.error(err);
            }
        };

        if(propertyList.length > 0)
            fetchFacings();
    }, [propertyList]);

    // Layout Type Filter
    const [roadSizes, setRoadSizes] = useState([]);
    const [selectedRoadSize, setSelectedRoadSize] = useState(null);
    const handleRoadSizeChange = (selectedOption) => {
        setSelectedRoadSize(selectedOption);
    };

    useEffect(() => {
        const fetchRoadSizes = async () => {
            try {
                const roadSizes = propertyList.map((result) => result.roadSize);
                setRoadSizes(removeDuplicates(roadSizes));
            } catch (err) {
                console.error(err);
            }
        };

        if(propertyList.length > 0)
            fetchRoadSizes();

    }, [propertyList]);

    // Plot Size Type Filter
    const [plotSizes, setPlotSizes] = useState([]);
    const [selectedPlotSize, setSelectedPlotSize] = useState(null);
    const handlePlotSizeChange = (selectedOption) => {
        setSelectedPlotSize(selectedOption);
    };

    useEffect(() => {
        const fetchPlotSizes = async () => {
            try {
                const plotSizes = propertyList.map((result) => result.plotArea);
                setPlotSizes(removeDuplicates(plotSizes));
            } catch (err) {
                console.error(err);
            }
        };

        if(propertyList.length > 0)
            fetchPlotSizes();
    }, [propertyList]);

    // Layout Type Filter
    const [layoutTypes, setLayoutTypes] = useState([]);
    const [selectedLayoutType, setSelectedLayoutType] = useState(null);

    const handleLayoutTypeChange = (selectedOption) => {
        setSelectedLayoutType(selectedOption);
    };

    useEffect(() => {
        const fetchLayoutTypes = async () => {
            try {
                const layoutTypes = propertyList.map((result) => result.layoutType);
                setLayoutTypes(removeDuplicates(layoutTypes));
            } catch (err) {
                console.error(err);
            }
        };

        if(propertyList.length > 0)
            fetchLayoutTypes();
    }, [propertyList]);

    //Filter properties based on the filters added.
    useEffect(() => {
        let filteredProperties = propertyList;

        if (selectedPlotType && selectedPlotType.length > 0) {
            filteredProperties = filteredProperties.filter((property) =>
                selectedPlotType.map(type => type.value).includes(property.type)
            );
        }

        if (selectedFacing && selectedFacing.length > 0) {
            filteredProperties = filteredProperties.filter((property) =>
                selectedFacing.map(facing => facing.value).includes(property.facing)
            );
        }

        if (selectedLayoutType && selectedLayoutType.length > 0) {
            filteredProperties = filteredProperties.filter((property) =>
                selectedLayoutType.map(layoutType => layoutType.value).includes(property.layoutType)
            );
        }

        if (selectedPlotSize && selectedPlotSize.length > 0) {
            filteredProperties = filteredProperties.filter((property) =>
                selectedPlotSize.map(plotSize => plotSize.value).includes(property.plotArea)
            );
        }

        if (selectedRoadSize && selectedRoadSize.length > 0) {
            filteredProperties = filteredProperties.filter((property) =>
                selectedRoadSize.map(roadSize => roadSize.value).includes(property.roadSize)
            );
        }

        setProperties(filteredProperties);
    }, [selectedPlotType, selectedFacing, selectedLayoutType, selectedPlotSize, selectedRoadSize, propertyList]);

    const styles = {
        color: 'white',
        marginTop: '2rem',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '1.2em', // Adjust the font size as needed
    };

    const phoneNumberStyles = {
        color: '#fff', // White color for better contrast
        fontWeight: 'bold', // Enhancing visibility with bold text
    };


    return (
        <div className="property-list">
                <div className="subFilter" >
                    <Select
                        id="Plottype"
                        onChange={handlePlotTypeChange}
                        options={plotTypes.map(plotType => ({ value: plotType, label: plotType }))}
                        value={selectedPlotType}
                        isMulti
                        isDisabled={properties.length===0}
                        placeholder="Plot Type"
                    />

                    <Select
                        id="facing"
                        onChange={handleFacingChange}
                        options={facings.map(facing => ({ value: facing, label: facing }))}
                        value={selectedFacing}
                        isMulti
                        placeholder="Facing"
                    />

                    <Select
                        id="layoutType"
                        onChange={handleLayoutTypeChange}
                        options={layoutTypes.map(layoutType => ({ value: layoutType, label: layoutType }))}
                        value={selectedLayoutType}
                        isMulti
                        placeholder="Layout Type"
                    />
                    <Select
                        id="roadSize"
                        onChange={handleRoadSizeChange}
                        options={roadSizes.map(roadSize => ({ value: roadSize, label: roadSize }))}
                        value={selectedRoadSize}
                        isMulti
                        placeholder="Road Size"
                    />
                    <Select
                        id="plotSize"
                        onChange={handlePlotSizeChange}
                        options={plotSizes.map(plotSize => ({ value: plotSize, label: plotSize }))}
                        value={selectedPlotSize}
                        isMulti
                        placeholder="Plot Size"
                    />
                    <h5 style={styles}>
                        For Site Visit, Please call <br /><AiTwotonePhone /><span style={phoneNumberStyles}>+91 9391 405 406</span><br /><br />
                        Please make sure that you mention the Property Code, which can be seen at the bottom of each card.
                    </h5>
                </div>
                <div className="list-p">
                    {properties.map((property) => (
                        <PropertyCard key={property.id} property={property} />
                    ))}
                </div>
        </div>
    );
};

export default PropertyList;

