import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aboutcard from "./AboutCard";
import Tilt from "react-parallax-tilt";
import myImg from "../../Assets/avatar.svg";
import Typewriter from "typewriter-effect";
import {AiTwotoneMail, AiTwotonePhone} from "react-icons/ai";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={12}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }} >
              <Col style={{paddingBottom:"3rem"}}>
                  <Typewriter
                      options={{
                          strings: [
                              "Transparency",
                              "VISION",
                              "Best Agent"
                          ],
                          autoStart: true,
                          loop: true,
                          deleteSpeed: 50,
                      }}
                  />
              </Col>
              <Aboutcard />
          </Col>
          <Col md={8} className="home-about-description">

                <p className="home-about-body">
                    Contact us Today<br />
                    <AiTwotoneMail /> Info@plottss.com <br />
                    <AiTwotonePhone />+91 9391 405 406 <br />
                </p>
          </Col>
          <Col md={4} className="myAvtar">
                <Tilt>
                    <img src={myImg} className="img-fluid" alt="avatar" />
                </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
