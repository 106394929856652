import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import "./ContactForm.css";
import {Container} from "react-bootstrap";
import Typewriter from "typewriter-effect";
import {insertRecord} from "../../config/DataService";

const ContactForm = () => {
    const initialValues = {
        name: '',
        phoneNumber: '',
        plotType: '',
        budget: '',
        locationPreference: '',
        note:'',
        contactType:''
    };

    const onSubmit = async(values, { resetForm }) => {
        // Handle form submission logic here

        const {data, error} = await insertRecord("customer", values);
        resetForm();
    };

    const validateForm = values => {
        const errors = {};

        // Implement your form validation logic here
        // For example, check if required fields are filled, validate format, etc.

        return errors;
    };

    return (
        <Container fluid className="home-section">
            <div style={{paddingBottom:"3rem"}}>
                <Typewriter
                    options={{
                        strings: [
                            "Buyer",
                            "Seller"
                        ],
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 50,
                    }}
                />
            </div>
            <div className="form-container">
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validate={validateForm}
                >
                    <Form className="form">
                        <div className="form-row">
                        <label htmlFor="contactType">Contact Type</label>
                        <Field as="select" id="contactType" name="contactType" required>
                            <option value="">Select Contact Type</option>
                            <option value="Buyer">Buyer</option>
                            <option value="Seller">Seller</option>
                        </Field>
                        <ErrorMessage name="contactType" component="div" />
                        </div>
                        <div className="form-row">
                        <label htmlFor="name">Name:</label>
                        <Field type="text" id="name" name="name" required />
                        <ErrorMessage name="name" component="div" />
                        </div>
                        <div className="form-row">
                            <label htmlFor="phoneNumber">Phone Number:</label>
                            <Field type="tel" id="phoneNumber" name="phoneNumber" required />
                            <ErrorMessage name="phoneNumber" component="div" />
                        </div>
                         <div className="form-row">
                            <label htmlFor="plotType">Property Type:</label>
                            <Field as="select" id="plotType" name="plotType" required>
                                <option value="">Select Property Type</option>
                                <option value="Open Plot">Open Plot</option>
                                <option value="Villa Plot">Villa Plot</option>
                                <option value="Farm Plot">Farm Plot</option>
                                <option value="Commerical Plot">Commerical Plot</option>
                                <option value="Premium Plot">Premium Plot</option>
                            </Field>
                            <ErrorMessage name="plotType" component="div" />
                        </div>
                        <div className="form-row">
                            <label htmlFor="budget">Budget:</label>
                            <Field type="text" id="budget" name="budget" required />
                            <ErrorMessage name="budget" component="div" />
                        </div>
                        <div className="form-row">
                            <label htmlFor="locationPreference">Location Preference:</label>
                            <Field type="text" id="locationPreference" name="locationPreference" required />
                            <ErrorMessage name="locationPreference" component="div" />
                        </div>
                        <div className="form-row">
                            <label htmlFor="note">Note:</label>
                            <Field
                                as="textarea"
                                id="note"
                                name="note"
                            />
                            <ErrorMessage name="note" component="div" />
                        </div>
                        <div className="form-row">
                            <button type="submit">Submit</button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </Container>
    );
};

export default ContactForm;
