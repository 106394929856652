import React, { useState } from 'react';
import {Container} from "react-bootstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import "./ContactForm.css"
import Typewriter from "typewriter-effect";
import {insertRecord} from "../../config/DataService";

const AgentForm = () => {

    const initialValues = {
        name: '',
        phoneNumber: '',
        district:'',
        mandal:'',
        village:'',
        note:''
    };

    const onSubmit = async(values, { resetForm }) => {
        // Handle form submission logic here
        const {data, error} = await insertRecord("agent", values);
        resetForm();
    };

    const validateForm = values => {
        const errors = {};

        // Implement your form validation logic here
        // For example, check if required fields are filled, validate email format, etc.

        return errors;
    };


    return (
        <Container fluid className="home-section">
            <div style={{paddingBottom:"3rem"}}>
                <Typewriter
                    options={{
                        strings: [
                            "Become an Agent"
                        ],
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 50,
                    }}
                />
            </div>
            <div className="form-container">
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validate={validateForm}
                >
                    <Form className="form">

                        <div className="form-row">
                            <label htmlFor="name">Name:</label>
                            <Field type="text" id="name" name="name" required />
                            <ErrorMessage name="name" component="div" />
                        </div>

                        <div className="form-row">
                            <label htmlFor="phoneNumber">Phone Number:</label>
                            <Field type="tel" id="phoneNumber" name="phoneNumber" required />
                            <ErrorMessage name="phoneNumber" component="div" />
                        </div>

                        <div className="form-row">
                            <label htmlFor="district">District:</label>
                            <Field type="text" id="district" name="district" required />
                            <ErrorMessage name="district" component="div" />
                        </div>

                        <div className="form-row">
                            <label htmlFor="mandal">Mandal:</label>
                            <Field type="text" id="mandal" name="mandal" required />
                            <ErrorMessage name="mandal" component="div" />
                        </div>

                        <div className="form-row">
                            <label htmlFor="village">Village:</label>
                            <Field type="text" id="village" name="village" required />
                            <ErrorMessage name="village" component="div" />
                        </div>

                        <div className="form-row">
                            <label htmlFor="note">Note:</label>
                            <Field
                                as="textarea"
                                id="note"
                                name="note"
                            />
                            <ErrorMessage name="note" component="div" />
                        </div>

                        <div className="form-row">
                            <button type="submit">Submit</button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </Container>
    );
};

export default AgentForm;
